import './App.css';
import  'bootstrap/dist/css/bootstrap.min.css';
import './i18n';
import Header from './Components/Header';
import Footer from './Components/Footer';

function App() {

  return (
    <div>
      <Header />
      <Footer />
    </div>
    
  );
}

export default App;
