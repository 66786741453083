import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Gallery } from 'react-grid-gallery';
import conveyor from '../Assets/Production/conveyor.jpg';
import gas from '../Assets/Production/gas.jpg';
import fence from '../Assets/Production/fence.jpg';
import container from '../Assets/Production/container.jpg';

export default function Production() {
    const { t } = useTranslation();
    const importAll = (r) => r.keys().map(r);
    const otherWorks = importAll(require.context('../Assets/Production/Other', false, /\.(png|jpe?g|svg)$/));

    const works = [
        { title: t('production.gas.title'), description: t('production.gas.description'), image: gas },
        { title: t('production.container.title'), description: t('production.container.description'), image: container },
        { title: t('production.conveyor.title'), description: t('production.conveyor.description'), image: conveyor },
        { title: t('production.support.title'), description: t('production.support.description'), image: conveyor },
        { title: t('production.fence.title'), description: t('production.fence.description'), image: fence },
    ];

    const galleryImages = otherWorks.map((image, index) => ({
        src: image,
        width: 320, 
        height: 200, // Adjust the height as per your images
        alt: `Other work ${index + 1}`,
        caption: t(`production.otherWorks.caption${index + 1}`, { defaultValue: `Other work ${index + 1}` }),
    }));

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-4">{t('production.heading')}</h1>

            {/* Main works */}
            <Row className="justify-content-center mb-5">
                <Col xs={12} md={8}>
                    <p className="text-center lead">{t('production.description')}</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={12} md={8}>
                    <ul className="list-unstyled">
                        {works.map((work, index) => (
                            <li key={index} className="mb-4 d-flex align-items-start">
                                <FaCheckCircle size={28} color="#28a745" className="me-3" style={{ flexShrink: 0 }} />
                                <div>
                                    <h5 className="fw-bold">{work.title}</h5>
                                    <p>{work.description}</p>
                                    <Image
                                        src={work.image}
                                        alt={work.title}
                                        style={{ width: '100%', borderRadius: '8px' }}
                                        className="mt-2 shadow-sm"
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>

            {/* Other works */}
            <h2 className="text-center mb-4 mt-5">{t('production.otherWorksHeading')}</h2>
            <Gallery images={galleryImages} />
        </Container>
    );
}
