import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next'; // Импорт HOC для переводов

import MetsoPower from '../Assets/ClientsLogo/Metso_Logo.png';
import FosterWheelerOY from '../Assets/ClientsLogo/FosterWheeler.jpg';
import KalmarMotor from '../Assets/ClientsLogo/KalmarMotor.png';
import RaumasterOY from '../Assets/ClientsLogo/RaumasterOY.png';
import NormekOY from '../Assets/ClientsLogo/Normek.png';
import NCC from '../Assets/ClientsLogo/ncc.png';
import ABB from '../Assets/ClientsLogo/ABB.png';
import EestiEnergia from '../Assets/ClientsLogo/EestiEnergia.png';

const clients = [
    { image: MetsoPower, url: 'https://www.metso.com/' },
    { image: FosterWheelerOY, url: 'https://www.woodplc.com/' },
    { image: KalmarMotor, url: 'https://kalmarmotor.com/en/start/' },
    { image: RaumasterOY, url: 'https://www.raumaster.fi/' },
    { image: NormekOY, url: 'https://normek.fi/normek/' },
    { image: NCC, url: 'https://www.ncc.com/' },
    { image: ABB, url: 'https://new.abb.com/ee' },
    { image: EestiEnergia, url: 'https://www.enefit.ee/' }
];

class ClientGrid extends Component {
    render() {
        const { t } = this.props; // Получаем функцию перевода
        return (
            <Container className="mt-5">
                <h2 className="text-center mb-2" style={{fontSize: "52px", fontWeight: "bold"}}>{t('clients.clientsHeader')}</h2>
                <p className="text-center mb-4">{t('clients.clientsDescription')}</p>
                <Row className="justify-content-center mb-4">
                    {clients.map((client, index) => (
                        <Col
                            key={index}
                            xs={6} sm={6} md={3} 
                            className="text-center mb-4"
                        >
                            <a
                                href={client.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <div
                                    className="shadow-sm p-4 rounded"
                                    style={{
                                        backgroundColor: 'white',
                                        border: '1px solid #EDEDED',
                                        height: '180px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={client.image}
                                        alt={`Client ${index + 1}`}
                                        className="img-fluid"
                                        style={{
                                            maxHeight: '120px',
                                            maxWidth: '100%'
                                        }}
                                    />
                                </div>
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(ClientGrid);