import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../SliderStyles.css';

import firstImage from '../Assets/SliderPhoto/slider_01.jpg';
import secondImage from '../Assets/SliderPhoto/slider_03.jpg';
import thirdImage from '../Assets/SliderPhoto/slider_04.jpg';
import fourthImage from '../Assets/SliderPhoto/slider_05.jpg';
import fifthImage from '../Assets/SliderPhoto/slider_06.jpg';
import sixthImage from '../Assets/SliderPhoto/slider_07.jpg';

import aboutpicture1 from '../Assets/PhotoWorker1.jpg';
import aboutpicture2 from '../Assets/PhotoWorker2.png';

import QualificationPhoto1 from '../Assets/QualificationPhoto1.jpg';
import QualificationPhoto2 from '../Assets/QualificationPhoto2.jpg';

const CompanyInfo = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

const images = [
    {
        src: firstImage,
        alt: t('slider.image1.alt'),
        title: t('slider.image1.title'),
        description: t('slider.image1.description'),
    },
    {
        src: secondImage,
        alt: t('slider.image2.alt'),
        title: t('slider.image2.title'),
        description: t('slider.image2.description'),
    },
    {
        src: thirdImage,
        alt: t('slider.image3.alt'),
        title: t('slider.image3.title'),
        description: t('slider.image3.description'),
    },
    {
        src: fourthImage,
        alt: t('slider.image4.alt'),
        title: t('slider.image4.title'),
        description: t('slider.image4.description'),
    },
    {
        src: fifthImage,
        alt: t('slider.image5.alt'),
        title: t('slider.image5.title'),
        description: t('slider.image5.description'),
    },
    {
        src: sixthImage,
        alt: t('slider.image6.alt'),
        title: t('slider.image6.title'),
        description: t('slider.image6.description'),
    },
];


  const cards = [
    { id: 1, title: t('card1.title'), description: t('card1.description') },
    { id: 2, title: t('card2.title'), description: t('card2.description') },
    { id: 3, title: t('card3.title'), description: t('card3.description') },
    { id: 4, title: t('card4.title'), description: t('card4.description') },
    { id: 5, title: t('card5.title'), description: t('card5.description') },
    { id: 6, title: t('card6.title'), description: t('card6.description') }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div>
        <div
            className="justify-content-center mb-5"
            style={{
            position: 'relative',
            height: '70vh',
            overflow: 'hidden',
            }}
        >
            <img
            src={images[currentSlide].src}
            alt={images[currentSlide].alt}
            style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                filter: 'brightness(0.45)',
                
            }}
            
            />
            <div
            style={{
                position: 'absolute',
                top: '42%',
                left: '7.5%',
                color: '#fff',
                textAlign: 'left',
                zIndex: 2,
            }}
            >
            <h2 style={{ fontSize: '64px', fontWeight: 'bold', marginBottom: '10px' }}>
                {images[currentSlide].title}
            </h2>
            <p style={{ fontSize: '18px', maxWidth: '45%' }}>
                {images[currentSlide].description}
            </p>
            </div>
            <div
            style={{
                position: 'absolute',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: '8px',
            }}
            >
            {images.map((_, index) => (
                <div
                key={index}
                onClick={() => setCurrentSlide(index)}
                style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: currentSlide === index ? '#fff' : 'rgba(255, 255, 255, 0.5)',
                    cursor: 'pointer',
                    transition: 'opacity 0.5s ease-in-out',
                }}
                ></div>
            ))}
            </div>
        </div>
        </div>


    <Container>
        <Row className="my-5">
            {/* Левая колонка с текстом */}
            <Col md={6} style={{ width: '46%', marginRight: '50px'}}>
            <h2 style={{ fontWeight: 'bold', fontSize: '52px' }}>{t('about.heading')}</h2>
            <h5 className="mb-3">{t('about.subtitle')}</h5>
            <p>{t('about.text1')}</p>
            <p>{t('about.text2')}</p>
            </Col>
            {/* Правая колонка с фотографиями */}
            <Col md={6} className="d-flex justify-content-between">
            <img
                src={aboutpicture1}
                alt={t('about.image1Alt')}
                style={{ width: '48%', objectFit: 'cover', borderRadius: '8px' }}
            />
            <img
                src={aboutpicture2}
                alt={t('about.image2Alt')}
                style={{ width: '48%', objectFit: 'cover', borderRadius: '8px' }}
            />
            </Col>
        </Row>
    </Container>


      <Container>
        <Row className="mt-5 mb-3 justify-content-center">
        <div style={{ width: '50%', textAlign: "center"}}>
            <h2 className="mb-2" style={{ fontWeight: 'bold', fontSize: '52px'}}>{t('achievements.heading')}</h2>
            <p>{t('achievements.description')}</p>
        </div>
        </Row>

        <Row className="mb-5 justify-content-center">
        {cards.map((item) => (
            <Col md={4} sm={6} xs={10} key={item.id} className="mb-3">
              <Card className="wider-card">
                <Card.Body>
                  <Card.Text className="wider-card-title">{item.title}</Card.Text>
                  <Card.Text className="wider-card-description">{item.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container>
            <Row className="my-5 mx-4" style={{ alignItems: "center" }}> {/* Вертикальное выравнивание */}
                <Col md={5}>
                    <img 
                        src={QualificationPhoto1}
                        alt={t('licensesImageAlt')} 
                        className="img-fluid"
                        style={{ borderRadius: "10px", marginBottom: "15px", maxHeight: "300px", width: "100%" }}
                    />
                    <img 
                        src={QualificationPhoto2} 
                        alt={t('licensesImageAlt2')} 
                        className="img-fluid"
                        style={{ borderRadius: "10px", maxHeight: "300px", width: "100%" }}
                    />
                </Col>
                <Col 
                    md={7}
                    style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "5em" }} // Центровка по высоте
                >
                    <h2 className="mb-4" style={{ fontWeight: 'bold', fontSize: '52px'}}>{t('licensesTitle')}</h2>
                    <p>{t('titleEmployeers')}</p>
                    <ul>
                        <li>{t('textEmployeers1')}</li>
                        <li>{t('textEmployeers2')}</li>
                    </ul>

                    <p>{t('titleServices1')}</p>
                    <p>{t('titleServices2')}</p>
                    <ul>
                        <li>{t('textServices1')}</li>
                        <li>{t('textServices2')}</li>
                        <li>{t('textServices3')}</li>
                        <li>{t('textServices4')}</li>
                        <li>{t('textServices5')}</li>
                    </ul>
                    <p>{t('testText')}</p>
                </Col>
            </Row>
        </Container>


    </>
  );
};

export default CompanyInfo;