import React, { Component } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import logo from '../Assets/logo.png';
import phoneIcon from '../Assets/Phone.png'; // Импорт иконки телефона как изображения
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import Contacts from '../Pages/Contacts';
import Production from '../Pages/Production';
import Clients from '../Pages/Clients';
class Header extends Component {
    changeLanguage = (lng) => {
        const { i18n } = this.props;
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);  
    };

    render() {
        const { t } = this.props; 

        return (
            <>
                <Navbar variant="light" collapseOnSelect expand="md" sticky="top" style={{ background: "white", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                alt=""
                                src={logo}
                                width="auto"
                                height="23"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                        <NavbarToggle aria-controls="responsive-navbar-nav" />
                        <NavbarCollapse id="responsive-navbar-nav">
                            <Nav className="me-auto" >
                                <Nav.Link style={{color:'black'}} href="/">{t('nav_home')}</Nav.Link>
                                <Nav.Link style={{color:'black'}} href="/production">{t('nav_firm')}</Nav.Link>
                                <Nav.Link style={{color:'black'}} href="/clients">{t('nav_clients')}</Nav.Link>
                                <Nav.Link style={{color:'black'}} href="/contacts">{t('nav_contacts')}</Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link href="tel:+1234567890" style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                                    <img 
                                        src={phoneIcon} 
                                        alt="Phone Icon" 
                                        width="20" 
                                        height="20" 
                                        style={{ marginRight: '5px' }} 
                                    />
                                    +372 354 9227
                                </Nav.Link>
                                <NavDropdown title={localStorage.getItem('language')} id="collasible-nav-dropdown">
                                    <NavDropdown.Item onClick={() => this.changeLanguage('ENG')}>
                                        English
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => this.changeLanguage('RUS')}>
                                        Русский
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => this.changeLanguage('EST')}>
                                        Eesti
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </NavbarCollapse>
                    </Container>
                </Navbar>
                <Router>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/production' element={<Production />} />
                        <Route exact path='/clients' element={<Clients />} />
                        <Route exact path='/contacts' element={<Contacts />} />
                    </Routes>
                </Router>

            </>
        );
    }
}

export default withTranslation()(Header);