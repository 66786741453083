import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha'; 
import {Col, Row} from 'react-bootstrap';
import Director from '../Assets/KonstantinKuznetsov.jpg';
import TechDirector from '../Assets/AleksandrDanilenkov.jpg';
export default function Contacts() {
    const { t } = useTranslation();
    const [mapLocation] = useState({
        lat: 59.3702461103463,
        lng: 28.13533122559512,
    });
    const [captchaToken, setCaptchaToken] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            alert('Пожалуйста, подтвердите капчу!');
            return;
        }

        
        const response = await fetch('/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData, captchaToken }),
        });
        const result = await response.json();
        if (response.ok) {
            alert('Сообщение успешно отправлено!');
            window.location.reload();
        } else {
            alert(`Ошибка: ${result.error || 'Неизвестная ошибка'}`);
        }
    };

    return (
        <>
        <Container className="mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '80%',
                }}
            >
                {/* Form Section */}
                <div style={{ flex: '0.4', marginRight: '20px' }}>
                    <h2 className="text-center m-4">{t('contacts.contactUsHeader')}</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{t('contacts.emailLabel')}</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder={t('example@gmail.com')}
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {/* <Form.Text className="text-muted">
                                {t('emailHelpText')}
                            </Form.Text> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{t('contacts.messageLabel')}</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                rows={3}
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <ReCAPTCHA
                            sitekey="6LeonroqAAAAAHVe--5_8KEOrX_1jUYKgLgi9t_o"
                            onChange={handleCaptchaChange}
                        />

                        <Button variant="primary" type="submit" className="w-100 mt-3">
                            {t('contacts.submitButton')}
                        </Button>
                    </Form>
                </div>

                {/* Map Section */}
                <div style={{ flex: '0.6', height: '400px' }}>
                    <iframe
                        title="Google Map"
                        src={`https://www.google.com/maps?q=${mapLocation.lat},${mapLocation.lng}&z=15&output=embed`}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            borderRadius: '10px',
                        }}
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </Container>
        <Container className="mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
        <Row style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '82%',
        }}>
            <Col>
                <div className="photo-item">
                <img
                    src={Director}
                    alt="Director Photo"
                    className="img-fluid"
                />
                <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                    <h5>{t('director')}</h5>
                    <p>{t('directorProfession')}</p>
                </div>
                </div>
            </Col>
            <Col>
                <div className="photo-item">
                <img
                    src={TechDirector}
                    alt="Technical Director"
                    className="img-fluid"
                />
                <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                    <h5>{t('techdirector')}</h5>
                    <p>{t('techdirectorProfession')}</p>
                </div>
                </div>
            </Col>
            </Row>
</Container>
</>
    );
}
