import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const language = localStorage.getItem('language') || 'ENG';
i18n.use(initReactI18next).init({
  resources: {
    ENG: {
      translation: require('../src/locales/ENG/translation.json'),
    },
    RUS: {
      translation: require('../src/locales/RUS/translation.json'),
    },
    EST: {
        translation: require('../src/locales/EST/translation.json'),
      },
  },
  lng: language, 
  fallbackLng: 'ENG',
  interpolation: {
    escapeValue: false, 
  },
});

export default i18n;
