import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo1 from '../Assets/UnitMet_whiteLogo.png';
import logo2 from '../Assets/Poisk_whiteLogo.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="mt-5">
            <iframe
                title="Google Map"
                src="https://www.google.com/maps?q=59.3702461103463,28.13533122559512&z=15&output=embed"
                style={{
                    width: '100%',
                    height: '300px',
                    border: 'none',
                    margin: '0'
                }}
            />

            <div
                fluid
                style={{
                    backgroundColor: '#454545',
                    color: '#fff',
                    padding: '2.5em 0em',
                    margin: '0'
                }}
            >
                <Container>
                    <Row >
                        {/* Первый столбец: Логотипы */}
                        <Col xs={12} md={3}>
                            <div>
                                <img
                                    className="mb-2"
                                    src={logo1}
                                    alt={t('footer.logo1Alt')}
                                    style={{ width: '52%' }}
                                />
                                <img
                                    src={logo2}
                                    alt={t('footer.logo2Alt')}
                                    style={{ width: '52%' }}
                                />
                            </div>
                        </Col>

                        {/* Третий столбец: Контактные данные */}
                        <Col xs={12} md={3}>
                            <strong>{t('footer.con')}</strong>
                            <ul className="mt-1" style={{ listStyle: 'none', padding: '0' }}>
                                <li>{t('footer.address')}</li>
                                <li>{t('footer.phone')}</li>
                                <li>{t('footer.mobile')}</li>
                                <li>
                                    {t('footer.email')}:{' '}
                                    <a
                                        href="mailto:poisknarva@bk.ru"
                                        style={{ color: '#fff', textDecoration: 'none' }}
                                    >
                                        poisknarva@bk.ru
                                    </a>
                                </li>
                            </ul>
                        </Col>

                        {/* Второй столбец: Банковские данные */}
                        <Col xs={12} md={3}>
                            <strong>{t('footer.bankDetails')}</strong>
                            <ul className="mt-1" style={{ listStyle: 'none', padding: '0', marginTop: '0' }}>
                                <li>{t('footer.ownername')}</li>
                                <li>{t('footer.regnum')}</li>
                                <li>{t('footer.bankname')}</li>
                                <li>{t('footer.banknum')}</li>
                            </ul>
                        </Col>
                        {/* Четвёртый столбец: Навигация */}
                        <Col xs={12} md={3}>
                            <strong>{t('footer.navigation')}</strong>
                            <ul className="mt-1" style={{ listStyle: 'none', padding: '0' }}>
                                <li>
                                    <a
                                        href="/"
                                        style={{ color: '#fff', textDecoration: 'none' }}
                                    >
                                        {t('footer.home')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/production"
                                        style={{ color: '#fff', textDecoration: 'none' }}
                                    >
                                        {t('footer.about')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/clients"
                                        style={{ color: '#fff', textDecoration: 'none' }}
                                    >
                                        {t('footer.services')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/contacts"
                                        style={{ color: '#fff', textDecoration: 'none' }}
                                    >
                                        {t('footer.contact')}
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                style={{
                    backgroundColor: '#333',
                    color: '#fff',
                    textAlign: 'center',
                    padding: '1em 0',
                    marginTop: '0'
                }}
            >
                &copy; 2025 UnitMet. {t('footer.allRightsReserved')}
            </div>
        </div>
    );
};

export default Footer;